<template>
  <div class="row">
    <va-modal
      v-model="newExamModal"
      :hide-default-actions="true"
      :title="'New Exam'"
      class="flex xs12"
      size="medium"
    >
      <div v-if="newExamStage === 1">
        <div>
          <va-input
            v-model="newExam.Name"
            placeholder="Title of the exam"
            :messages="['The name of the exam that shows up on the certificate.']"
          />
        </div>
        <div class="flex xs12">
          <va-select
            :label="'Number of Patients'"
            v-model="patientCountModel"
            text-by="description"
            :options="simpleOptions"
            no-clear
          />
        </div>
        <div>
          <va-button
            :disabled="newExam.Name === '' || patientCountModel.id === ''"
            @click="newExamStage = 2"
            :title="newExam.Name === '' || patientCountModel.id === '' ? 'Please fill out all fields' : ''"
            color="#6C7FEE"
          >
            Next
            <i
              class="fa fa-lock"
              style="margin-left: 0.4em; margin-top: 0.1em;"
              v-if="newExam.Name === '' || patientCountModel.id === ''"
            />
            <i
              class="fa fa-unlock"
              style="margin-left: 0.4em; margin-top: 0.1em;"
              v-else
            />
          </va-button>
          <va-button
            @click="newExamModal = false"
            color="#f44336"
          >
            Close
          </va-button>
        </div>
      </div>
      <div v-if="newExamStage === 2">
        <div
          v-for="index in parseInt(patientCountModel.id)"
          :key="index"
        >
          <va-select
            :label="'Patient #' + index"
            searchable
            v-model="newExam.Models[index-1]"
            text-by="name"
            :options="models"
          />
        </div>
        <div>
          <va-button
            @click="saveNewExam"
            :disabled="!checkNewExamModelValues(newExam)"
            :title="!this.lastCheckedExam ? 'Please fill out all fields' : ''"
            color="#6C7FEE"
          >
            Create New Exam
            <i
              class="fa fa-lock"
              style="margin-left: 0.4em; margin-top: 0.1em;"
              v-if="!this.lastCheckedExam"
            />
            <i
              class="fa fa-unlock"
              style="margin-left: 0.4em; margin-top: 0.1em;"
              v-else
            />
          </va-button>
          <va-button
            @click="newExamModal = false"
            color="#f44336"
          >
            Close
          </va-button>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="showModal"
      :hide-default-actions="true"
      class="flex xs12"
      :title="'Editing Exam - ' + modalData.Name"
      size="large"
    >
      <div
        class="modal"
        v-if="modalData.Model"
      >
        <h3>
          Exam ({{ modalData.Name }})
        </h3>
   
        <div class="mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <div style="flex-grow: 1; margin-right: 1em;">
              <va-input
                v-model="modalData.Name"
                placeholder="Title of the exam"
                :messages="['The name of the exam that shows up on the certificate.']"
              />
            </div>
            <div>
              <va-toggle
                v-model="modalData.Active"
                small
                label="Active"
              />
            </div>
          </div>
          <div
            v-for="model in modalData.Models"
            :key="model.name"
            />
            <!-- {{ selectedModel }} -->
            <va-button-toggle
              v-model="selectedModel"
              :options="modelOptions"
            />
          </div>


          <div>
            <div class="flex md12">
                <va-card :title="'Exam Questions'" v-if="renderComponent" >
                  <va-data-table
                    :handle-data-update="false"
                    :fields="ExamQuestions"
                    :data="FormatExamQuestionData(modalData, translationData)"
                    :per-page="5"
                    hoverable
                  >
                    <template slot="correctAnswer" slot-scope="props" >
                      <p style="cursor: pointer;" :title="props.rowData.fullCorrectAnswer">{{ props.rowData.correctAnswer }}</p>
                    </template>
                    <template
                      slot="actions"
                      slot-scope="props"
                    >
                        <va-button
                          small
                          @click="EditCurrentAnswerData(props.rowData, modalData)"
                        >
                        View/Update 
                      </va-button>
                    </template>
                  </va-data-table>
                </va-card>
           </div>
        </div>
       
      </div>




      <div :slot="'actions'">
        <va-button
          @click="cancelModal"
          color="#cccccc"
        >
          Cancel
        </va-button>
        <va-button
          @click="saveEdit"
          color="#85bb65"
        >
          Save Exam!
        </va-button>
        <va-button
          @click="deleteData"
          :style="{ 'float': 'right' }"
          color="#d20000"
        >
          Delete
        </va-button>
      </div>
    </va-modal>


    <va-modal
      v-model="FilterByPatientModal"
      :hide-default-actions="true"
      :title="'Filter By Patient'"
      class="flex xs12"
      size="medium"
    >
        <div class="flex xs12">
            <va-select
              :label="'Patient'"
              v-model="filterByPatientOptions"
              text-by="name"
              :options="models"
              multiple
            />
        </div>
        <div>
          <va-button
            @click="filterPatients"
            color="#6C7FEE"
          >
            Filter
          </va-button>
          <va-button
            @click="FilterByPatientModal = false"
            color="#f44336"
          >
            Close
          </va-button>
        </div>
    </va-modal>


    <va-modal
      v-model="editCurrentQuestionModal"
      size="large"
      class="flex xs12"
      :hide-default-actions="true"
      :title="'Editing Exam Question: '"
    > 
      <div class="mx-0">
        <div style="max-height: 400px; overflow-y: auto;">
            <div>
              <div>
              <va-card
                :title="'Question: ' + this.get('questionTitle')"
                class="p-3"
              > 
                  <strong>Correct Choice / View Choices:</strong>
                  <div>
                    <va-select
                      style="max-width: 100%;"
                      v-model="storedValues.correctAnswerIndex"
                      :options="this.get('questionChoices')"
                      key-by="value"
                      text-by="label"
                      :no-clear="true"
                    >
                    </va-select> 
                  </div>

                  <strong>Question Props / View Props:</strong>
                  <div>
                    <va-select
                        v-model="storedValues.currentPropData"
                        :options="dataprops"
                        multiple
                      />
                  </div>
             
              </va-card>
            </div>
            </div>
          </div>
      </div> 
      <div :slot="'actions'">
        <template>
          <va-button
            @click="storeQuestionChanges()"
            color="#4040ff"
          >
            Store 
          </va-button>
          <va-button
            @click="editCurrentQuestionModal = false"
            color="#cccccc"
          >
            Close
          </va-button>
        </template>
      </div>
    </va-modal>






    <div class="flex md12">
      <va-card :title="'Exams List'">
        <div class="row align--center">
          <div class="flex xs12 md6">
              <va-input
                :value="term"
                :placeholder="'Search'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md3">
              <va-select
                v-model="advancedFilter"
                label="Advanced Filters"
                text-by="description"
                :options="advancedFilterOptions"
              />
            </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="10"
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              flat
              small
              color="gray"
              @click="edit(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.edit') }}
            </va-button>

            <va-button
              flat
              small
              color="danger"
              @click="remove(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.delete') }}
            </va-button>
          </template>
        </va-data-table>
        <va-button
          :disabled="newExamModal"
          :color="newExamModal ? '#f44336' : '#6C7FEE'"
          @click="createExam()"
        >
          Create Exam
          <i
            class="fa fa-plus-circle"
            style="margin-left: 1em;"
            v-if="!newExamModal"
          />
          <i
            class="fa fa-minus-circle"
            style="margin-left: 1em;"
            v-if="newExamModal"
          />
        </va-button>
      </va-card>
    </div>
  </div>
</template>

<script>
//import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import moment from 'moment';
import {OrbitSpinner} from 'epic-spinners';
import dataprops from '../../data/props';
import emptyExam from '../../data/emptyExam.json';
import {debounce} from 'lodash';
import ClickToEdit from '../ClickToEdit.vue';
import axios from '@/scripts/interceptor.js'

export default {
  name: 'Admin',
  data: function() {
    return {
      renderComponent: true,
      storedValues: {
        correctAnswerIndex: false,
        currentPropData: [],
      },
      lastUpdatedList: [],
      loading: false,
      exams: [],
      models: [],
      newExam: {
        Name: '',
        Models: [{id: 0}, {id: 0}, {id: 0}],
      },
      advancedFilter: '',
      advancedFilterOptions: [
        {
          id: 1,
          description: 'Number of Exams',
        },
        {
          id: 3,
          description: 'By Creation Date',
        },
        {
          id: 2,
          description: 'By Patient',
        },
      ],
      currentBlueprint: {},
      modelOptions: [],
      currentRowData: {},
      term: '',
      selectedProps: [], 
      lastCheckedExam: false,
      selectedModel: '',
      newExamStage: 1,
      modalData: {name: null},
      showModal: false,
      FilterByPatientModal: false,
      newExamModal: false,
      translationData: {},
      editCurrentQuestionModal: false,
      searchableSelectModel: '',
      dataprops: dataprops,
      emptyExam: emptyExam,
      examsCopy: [],
      filterByPatientOptions: [],
      patientCountModel: {id: 3, description: 'Three Patients'},
      simpleOptions: [
        {
          id: 2,
          description: 'Two Patients',
        },
        {
          id: 3,
          description: 'Three Patients',
        },
        {
          id: 4,
          description: 'Four Patients',
        },
        {
          id: 5,
          description: 'Five Patients',
        },
        {
          id: 6,
          description: 'Six Patients',
        },
      ],
    };
  },
  components: {
    OrbitSpinner,
    ClickToEdit,
  },
  async created() {
    //remove the empty option value from the this.dataprops
    this.dataprops = this.dataprops.filter(item => item !== '');
    this.getExams();
    this.getModels();
    this.getTranslationData();
  },
  methods: {
    get(field){
      switch(field){
        case 'questionTitle': 
          return this.currentRowData?.question?.Name
        case 'questionText':
          return this.currentRowData?.question?.QuestionText
        case 'questionChoices':
          let Answers = this.currentRowData?.question?.Answers;
          if(Answers){
            let data=  Answers.map((answer, index) => ({
              value: index,
              label: this.translationData[answer.AnswerText],
            }));
            return data;
          } else {
            return [];
          }
        break;
      }
    },
    storeQuestionChanges(){
      //find in this.lastUpdatedList the one that has name = this.currentRowData.name
      let question = this.lastUpdatedList.currentRow.find(question => question.name == this.currentRowData.name)

      // Now you can modify the question object
      if (question) { // This check is to make sure we found a question with the given name
          //get the index of the correct answer and check if it is equal to the storedValues.correctAnswerIndex, if yes, do nothing, if not, change the correct answer
          let correctAnswerIndex = question.question.Answers.findIndex(answer => answer.Correct == true)
          if(correctAnswerIndex != this.storedValues.correctAnswerIndex.value){
            //change the correct answer
            question.question.Answers[correctAnswerIndex].Correct = false
            question.question.Answers[this.storedValues.correctAnswerIndex.value].Correct = true
          }
          //change the question prop and save
          question.question.Prop = this.storedValues.currentPropData
      }
      this.editCurrentQuestionModal = false
      this.activityToast('Stored')
    },
    FormatExamQuestionData(currentExam, translationData) {
      try {
        this.lastUpdatedList = [];
        let modelIndex = currentExam.Models.findIndex(model => model.name == this.selectedModel)
        let FormatedQuestionList = []
        let startQuestionIndex = modelIndex * 15; // calculate start index for the model
        let endQuestionIndex = startQuestionIndex + 15; // calculate end index for the model

        for (let i = startQuestionIndex; i < endQuestionIndex; i++){
          const question = currentExam.questions[i];
          // in question.Answers they have a boolean "correct" that is true if the answer is correct, get the correct answer
          let correctAnswer = question.Answers.find(answer => answer.Correct == true)
          FormatedQuestionList.push({
            name: `${question.QuestionText.replace('ExamQuestion', '')} - ${question.Name}` ,
            fullCorrectAnswer: `${question.Name}`,
            correctAnswer: translationData[correctAnswer.AnswerText + '_abbrv'] ,
            question: question,
          })
        }
        this.lastUpdatedList = {
          currentRow: FormatedQuestionList,
          total: currentExam.questions,
          modelIndex: modelIndex,
          startQuestionIndex: startQuestionIndex,
          endQuestionIndex: endQuestionIndex,
        };
        return FormatedQuestionList
      } catch (error) {
        return []
      }
    },

    EditCurrentAnswerData(rowData, modalData){
      let modelIndex = modalData.Models.findIndex(model => model.name == this.selectedModel)
      this.currentRowData = rowData;
      // Check if lesson.prop is an array for each lesson
      if (!Array.isArray(this.currentRowData.question.Prop)) {
        this.currentRowData.question.Prop = [];
      }
      //find the correct answer in this.currentRowData.question.Answers 
      let correctAnswer = this.currentRowData.question.Answers.find(answer => answer.Correct == true)
      //get the index of the correct answer in the "Answers" array
      let correctAnswerIndex = this.currentRowData.question.Answers.indexOf(correctAnswer)
      this.storedValues.currentPropData = this.currentRowData.question.Prop;

      this.storedValues.correctAnswerIndex = {
        value: correctAnswerIndex,
        label: this.translationData[correctAnswer.AnswerText],
      };
      this.editCurrentQuestionModal = true; 
    },
    activityToast(text) {
     this.$swal.fire({
        title: text, 
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showCloseButton: false,
        showCancelButton: false,
        cancelButtonColor: '#d33',
        confirmButtonText: 'View',
        confirmButtonColor: '#3085d6',
      })
    },
    checkNewExamModelValues() {
      let valid = true;
      this.newExam.Models.forEach((model) => {
        if (model.id === 0 || !model.id ) {
          valid = false;
        }
      });
      this.lastCheckedExam = valid;
      return valid;
    },
    getTranslationData() {
      const i8ln = firebase.firestore().collection('i18n').doc('English');
      i8ln.get().then((snapshot) => {
        this.translationData = snapshot.data();
      });
    },
    async getModels() {
      const models = firebase.firestore().collection('model').where('Active', '==', true);

      models.get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          const model = {};
          model.id = doc.id;
          model.name = data.patientName;
          model.blueprint = data.blueprint ? data.blueprint : false;
          this.models.push(model);
        });
      });
    },
    async getExams() {
      this.exams = [];

      const test = firebase.firestore().collection('Test');

      const query = test.where('Deleted', '==', false).orderBy('Active', 'desc');
      query.get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              const data = doc.data();
              data.FormattedCreatedDate = moment.unix(data.CreatedDate.seconds).format('LL');
              data.creationDateFilter = (data.CreatedDate.seconds).toString();
              data.activeFilter = data.Active ? 'Active' : 'Not Active';
              data.questionsFilter = (data.questions.length).toString();
              data.id = doc.id;
              if (data.Models === undefined) {
                data.Model = await data.Model.get().then((snapshot) => {
                  const modelData = snapshot.data();
                  modelData.id = snapshot.id;
                  return modelData;
                });
              } else {
                data.Model = {};
                const modelQuery = [];
                data.Models.forEach((model) =>{
                  modelQuery.push(model.get().then((snapshot)=>{
                    return snapshot.data();
                  }));
                });
                Promise.all(modelQuery).then((modelData)=>{
                  data.Models = modelData;
                });
                data.questions.forEach((question)=>{
                  try {
                    question.Model.get().then((snapshot)=>{
                        question.Model = snapshot.data();
                        question.Model.id = snapshot.id;
                    });
                  } catch (error) {
                    return;
                  }
                });
              }
              data.questions.forEach((question) => {
                question.edit = false;
                question.Answers.forEach((answer) => {
                  answer.edit = false;
                });
                if (question.Prop.length > 0) {
                  question.Prop = question.Prop.split(',');
                } else {
                  question.Prop = [];
                }
              });
              this.exams.push(data);
            });
            this.examsCopy = this.exams;
          });
    },
    cancelModal() {
      this.showModal = false;
    },
    saveEdit(modalData) {
      const exam = firebase.firestore().collection('Test').doc(this.modalData.id);

      this.modalData.questions.forEach((question) => {
        if (question.Prop.length === 0) {
          question.Prop = '';
        } else {
          question.Prop = question.Prop.join(',');
        }
        question.Model = firebase.firestore().collection('model').doc(question.Model.id);
      });
      exam.update({
        Name: this.modalData.Name,
        Active: this.modalData.Active,
        questions: this.modalData.questions,
      }).then(() => {
        this.showModal = false;
        this.modalData = {name: null};
        this.getExams();
      });
    },
    deleteData() {
      this.remove(this.modalData);
    },
    edit(exam) {
      this.modalData = exam;
      this.showModal = true;
      this.modelOptions = [];
      // setting the model options
      this.modalData.Models.forEach((model, index)=>{
        if (index === 0) {
          this.selectedModel = model.name;
        }
        this.modelOptions.push({
          label: model.name,
          value: model.name,
        });
      });
      this.modelOptions;
    },
    updateText(key, textData) {
      const tempField = {};
      tempField[key] = textData;
      firebase.firestore().collection('i18n').doc('English').update(tempField);
    },
    remove(exam) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Delete Exam',
        html: 'Are you sure you want to delete "<b>' + exam.Name + '</b>"?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false;
          firebase.firestore().collection('Test').doc(exam.id).update({
            Active: false,
            Deleted: true,
            DeletedAt: firebase.firestore.Timestamp.now(),
          }).then(() => {
            this.getExams();
          });
        }
      });
    },
    async filterPatients(){
      //for each this.exams[index].Models, itinerate, and check if one of the models match the at least one id from this.filterByPatientOptions.id
      let filteredExams = this.exams.filter(exam => {
        let filteredModels = exam.Models.filter(model => {
          console.log(model)
          if(model){
            let found = this.filterByPatientOptions.find(option => option.name == model.patientName)
            if(found){
              return true
            } else {
              return false
            }
          }else{
            return false
          }
        })
        if(filteredModels.length > 0){
          return true
        } else {
          return false
        }
      })
      this.exams = filteredExams
      this.FilterByPatientModal = false
      this.filterByPatientOptions = []
      this.activityToast('Filtered')
    },
    async saveNewExam() {
      for (const i in this.newExam.Models) {
        if (this.newExam.Models[i].id === 0) {
          alert('Select All Patients');
          break;
        }
      }
      // verifica se não tem pacientes repetidos, se tiver, abre um swal
      let isDismissed = false;
      const patients = [];
      for (const i in this.newExam.Models) {
        if (patients.includes(this.newExam.Models[i].id)) {
          await this.$swal.fire({
            icon: 'warning',
            title: 'Duplicate Patients',
            html: 'Patient "<b>' + this.newExam.Models[i].name + '</b>" is repeated.',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Ignore & Continue',
            cancelButtonText: 'Close',
            // focus in cancel button
            didOpen: () => {
              document.querySelector('.swal2-cancel').focus();
            },
            customClass: {
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
            },
          }).then((result) => {
            // if user clicks on close, stop function
            if (result.isDismissed) {
              isDismissed = true;
            }
          });
        } else {
          if (isDismissed) return;
          patients.push(this.newExam.Models[i].id);
        }
      }
      if (isDismissed) return;
      const data = {
        CreatedDate: firebase.firestore.Timestamp.now(),
        Name: this.newExam.Name,
        PassingScore: 93,

        Active: false,
        Deleted: false,

      };
      data.Models = this._buildModels();
      data.questions = this._buildQuestions();

      firebase.firestore().collection('Test').add(data).then(() => {
        this.newExamModal = false;
        this.newExam = {
          Name: '',
          Models: [{id: 0}, {id: 0}, {id: 0}],
        },
        this.newExamStage = 1;
        this.modalData = {name: null};
        this.patientCountModel = {id: 3, description: 'Three Patients'},
        this.getExams();
      });
    },
    _buildQuestions() {
      const questions = [];
      this.newExam.Models.forEach(async (model) => {
        const examTemplate = JSON.parse(JSON.stringify(this.emptyExam));
        examTemplate.forEach(async (question, index) => {
          question.Model = firebase.firestore().collection('model').doc(model.id);
          if (model.blueprint) {
            const blueprintQuestion = model.blueprint[index];
            question.Answers[blueprintQuestion.answer].Correct = true;
          }
          questions.push(question);
        });
      });
      return questions;
    },
    _buildModels() {
      const models = [];
      this.newExam.Models.forEach((model) => {
        models.push(firebase.firestore().collection('model').doc(model.id));
      });
      return models;
    },
    createExam() {
      this.newExamModal = true;
      this.modalData = {name: null};
      this.patientCountModel = {id: 3, description: 'Three Patients'},
      this.newExam = {
        Name: '',
        Models: [{id: 0}, {id: 0}, {id: 0}],
      },
      this.newExamStage = 1;
    },
    search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
    }, 100),
  },
  watch: {
    selectedModel(newValue, oldValue) {
      this.renderComponent = false;
      this.activityToast(`Model - ${newValue}`);
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    async advancedFilter(newValue, oldValue) {
      // Exibir um modal de carregamento
      this.$swal({
        title: 'Loading...',
        html: "Please wait while we filter the exams by the selected filter, <b>this may take a while.</b>",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      switch (newValue.id) {
        case 1:
          try {
            let response = await axios.get('api/admin/exams/filter');
            let examsWithCompletedAmount = response.data.examsWithCompletedAmount;
            this.exams = this.exams
              .map(exam => {
                const completed = examsWithCompletedAmount[exam.id]?.completed || 0;
                return { 
                  ...exam, 
                  completed,
                  completedExams: completed,
                  completedExamsFilter: completed.toString()
                };
            })
            .sort((a, b) => b.completed - a.completed);
          } catch (error) {
            console.error(error);
          } finally {
            this.$swal.close();
          }
          break;
        case 2: 
          // Filter by patient
          this.FilterByPatientModal = true;
          this.$swal.close();
          break;
        case 3: 
          this.exams = this.examsCopy
          this.exams.sort((a, b) => {
            return new Date(b.CreatedDate.seconds) - new Date(a.CreatedDate.seconds);
          });
          this.$swal.close();
          break;
        default:
          this.exams = this.examsCopy
          //remove completedExams field from the exams
          this.exams.forEach(exam => {
            delete exam.completedExams
            delete exam.completedExamsFilter
          })
          this.exams.sort((a, b) => {
            return new Date(b.CreatedDate.seconds) - new Date(a.CreatedDate.seconds);
          });
          this.$swal.close();
          break;
      }
    },
  },
  computed: {
    filteredData(){
      if (!this.term || this.term.length < 1) {
        return this.exams;
      }
      //filter by questionsFilter using term as a filter
      return this.exams.filter((item) => {
          let returnData = false;
          if (item.Name) {
            if (item.Name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.questionsFilter) {
            if (item.questionsFilter.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.FormattedCreatedDate) {
            if (item.FormattedCreatedDate.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.activeFilter) {
            if (item.activeFilter.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          return returnData;
        });
    },  
    ExamQuestions(){
      return [
          {
            name: 'name',
            title: 'Question Name',
          },
          {
            name: '__slot:correctAnswer',
            title: 'Correct Answer',
          },{
            name: '__slot:actions',
            title: 'Actions',
          }
      ]
    },
    fields() {
      let output = []
      if(this.exams.length > 0 && typeof this.exams[0]?.completedExams == 'number'){
        output = [{
          name: 'Name',
          title: 'Exam Name',
          sortField: 'Name',
        }, {
          name: 'FormattedCreatedDate',
          title: 'Creation Date',
          sortField: 'creationDateFilter',
        }, {
          name: 'activeFilter',
          title: 'Active',
          sortField: 'activeFilter',
        }, {
          name: 'questions.length',
          title: 'Number of Questions',
          sortField: 'questionsFilter',
        },
        {
          name: 'completedExams',
          title: 'Exams Completed',
          sortField: 'completedExamsFilter',
        }, {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]; 
      } else {
        output = [{
          name: 'Name',
          title: 'Exam Name',
          sortField: 'Name',
        }, {
          name: 'FormattedCreatedDate',
          title: 'Creation Date',
          sortField: 'creationDateFilter',
        }, {
          name: 'activeFilter',
          title: 'Active',
          sortField: 'activeFilter',
        }, {
          name: 'questions.length',
          title: 'Number of Questions',
          sortField: 'questionsFilter',
        }, {
          name: '__slot:actions',
          dataClass: 'text-right',
        }];
      }
      return output;
    },
  },

};
</script>

